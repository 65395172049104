import React from 'react'
import UserProfile from '../components/profile/UserProfile'

function Profile() {
  return (
    <div>
      <UserProfile />
    </div>
  )
}

export default Profile